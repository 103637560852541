<template>
    <v-layout fill-height>
        <v-col wrap justify-center>
            <v-flex md12>
                <material-card title="Wellstar Data"
                               text="Show Wellstar invoice and quote data">

                    <w-orders-table
                                    :headers_one="headers_one"
                                    :headers_two="headers_two"
                    ></w-orders-table>

                </material-card>
            </v-flex>
        </v-col>
    </v-layout>
</template>

<style>
    .v-data-table td, .v-data-table th {
        padding:0;
    }
</style>
<script>
    export default {
        name: "admin_orders",
        data: () => ({
            //Loading data
            title: "Wellstar Data",
            headers_one: [
                {
                    text: 'Chat',
                    align: 'center',
                    value: 'chat',
                    width:50,
                    custom: true,
                },
                {
                    text: 'Stage',
                    align: 'center',
                    value: 'stage',
                    width:80,
                    custom: true,
                },
                {
                    text: 'Pi Number',
                    align: 'center',
                    value: 'pi_number',
                    custom: false,
                    width:70,
                    truncate:true
                },
                {
                    text: 'Actions',
                    align: 'center',
                    value: 'actions',
                    custom: true,
                    width:50,
                },
                {
                    text: 'Dept.',
                    align: 'center',
                    value: 'department_name',
                    truncate: true,
                },
                {
                    text: 'Descr',
                    align: 'center',
                    value: 'description',
                    truncate: true,
                    width:200
                },
                {
                    text: 'Shipped',
                    icon: 'mdi-truck-fast',
                    align: 'center',
                    value: 'shipped',
                    custom: true,
                    width:50
                },
                {
                    text: 'Date',
                    icon: 'mdi-calendar-clock',
                    align: 'center',
                    value: 'safe_date',
                    custom: false,
                    width:100
                },
                {
                    text: 'Customer',
                    align: 'center',
                    value: 'customer',
                    custom: true,
                    width:150,
                    truncate:true
                },
                {
                    text: 'SalesMan',
                    align: 'center',
                    value: 'salesman',
                    custom: true,
                    truncate:true,
                    width:100
                },
                {
                    text: 'PO',
                    align: 'center',
                    value: 'po_number',
                    width:70,
                    truncate:true,
                },
            ],
            headers_two: [
                {
                    text: 'Fact PI',
                    align: 'center',
                    value: 'factory_pi',
                    currency:true,
                    width:130
                },
                {
                    text: 'Sales PI',
                    align: 'center',
                    value: 'sales_pi',
                    currency:true,
                    width:150,
                },
                {
                    text: '%',
                    align: 'center',
                    value: 'percentage',
                },
                {
                    text: 'Profit',
                    align: 'center',
                    value: 'profit',
                    currency:true,
                    custom:true
                },
                {
                    text: 'Factory Inv',
                    align: 'center',
                    value: 'factory_pi',
                    currency:true,
                    width:130
                },
                {
                    text: 'Factory Diff',
                    align: 'center',
                    value: 'factory_diff',
                    currency:true,
                    custom:true,
                    width:130
                },
                {
                    text: 'Client Final Inv',
                    align: 'center',
                    value: 'clients_total_sales',
                    currency:true,
                    width:150
                },
                {
                    text: 'Client Diff',
                    align: 'center',
                    value: 'sales_diff',
                    currency:true,
                    custom:true,
                    width:150
                },
                {
                    text: 'Partner Diff',
                    align: 'center',
                    value: 'partner_profit',
                    currency:true,
                    width:150
                },
                {
                    text: 'Profit',
                    align: 'center',
                    value: 'wellstar_final_income',
                    currency:true,
                }
            ],
            //Info to show
            headers: [
                {
                    text: 'Chat',
                    align: 'center',
                    value: 'chat',
                    custom: false,
                },
                {
                    text: 'Stage',
                    align: 'center',
                    value: 'stage',
                    custom: true,
                },
                {
                    text: 'Pi Number',
                    align: 'center',
                    value: 'pi_number',
                    custom: false,
                },
                {
                    text: 'Actions',
                    align: 'center',
                    value: 'actions',
                    custom: true,
                },
                {
                    text: 'Dept.',
                    align: 'center',
                    value: 'department_name'
                },
                {
                    text: 'Descr',
                    align: 'center',
                    value: 'description',
                    truncate: true
                },
                {
                    text: 'Shipped',
                    icon: 'mdi-truck-fast',
                    align: 'center',
                    value: 'shipped',
                    custom: true
                },
                {
                    text: 'Date',
                    icon: 'mdi-calendar-clock',
                    align: 'center',
                    value: 'safe_date',
                    custom: false
                },
                {
                    text: 'Customer',
                    align: 'center',
                    value: 'customer',
                    custom: false,
                },
                {
                    text: 'SalesMan',
                    align: 'center',
                    value: 'salesman',
                    custom: false,
                },
                {
                    text: 'PO',
                    align: 'center',
                    value: 'po_number',
                },
                {
                    text: 'Fact PI',
                    align: 'center',
                    value: 'factory_pi',
                },
                {
                    text: 'Sales PI',
                    align: 'center',
                    value: 'sales_pi',
                },
                {
                    text: '%',
                    align: 'center',
                    value: 'percentage',
                },
                {
                    text: 'Profit',
                    align: 'center',
                    value: 'profit',
                },
                {
                    text: 'Factory Inv',
                    align: 'center',
                    value: 'factory_pi',
                },
                {
                    text: 'Factory Diff',
                    align: 'center',
                    value: 'factory_diff',
                },
                {
                    text: 'Client Final Inv',
                    align: 'center',
                    value: 'final_sales_pi',
                },
                {
                    text: 'Client Diff',
                    align: 'center',
                    value: 'sales_diff',
                },
                {
                    text: 'Partner Diff',
                    align: 'center',
                    value: 'partner_profit',
                },
                {
                    text: 'Profit',
                    align: 'center',
                    value: 'wellstar_final_income',
                }
            ],
        }),
    }
</script>
